import React from "react"
import Layout from "../layouts/layout"
import Head from "../components/head"

const About = () => {
  return (
    <Layout>
      <Head title="About" />
      <h1>&lt;About /&gt;</h1>
      <p>
        I am a simple Indian guy who loves to watch movies, hangout with
        friends, explore new things.
      </p>
      <p>
        I love to read books, explore new API and build something awesome and
        creative.
      </p>
    </Layout>
  )
}

export default About
